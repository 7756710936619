import React from 'react';
import { Auth } from 'aws-amplify';
import { closeBroadcastChannel } from '../../Events/BroadcastNotificationHandler';
import { unloadSubscribers } from '../../PubSub/Subscribers';
import { revokeCognitoRefreshToken } from '../../Repository/CognitoRepository';

export const handleLogout = async (signOut) => {
  try {
    closeBroadcastChannel();
    await revokeCognitoRefreshToken();
    sessionStorage.removeItem('aryaidp');
    Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });
    unloadSubscribers();
    signOut();
  } catch (error) {
    console.error('Error during sign-out:', error);
  }
};
