import React from 'react';
import { Auth } from 'aws-amplify';
import * as ReactRedux from 'react-redux';
import { handleLogout } from './HandleLogout';
import signOutAction from '../../Actions/AuthActions';

export default function UpdatePasswordButton(props) {
  const { renderProp } = props;
  const dispatch = ReactRedux.useDispatch();

  const onSuccessfulPasswordUpdateCallback = async () => {
    await Auth.signOut({ global: true });
    handleLogout(signout);
  };
  const signout = () => {
    dispatch(signOutAction());
  };

  const client = localStorage.getItem('Client');
  const { hostname } = window.location;
  let linkUrl = '/';
  if (client && hostname.includes('goarya.com')) {
    linkUrl = `/${client}`;
  }

  return renderProp(onSuccessfulPasswordUpdateCallback);
}
