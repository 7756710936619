/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Affix, Input, Button, Form, Icon } from 'antd';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import cardValidator from 'card-validator';
import { parseHtmlStringFromEditor } from '../../Utils/DomParserUtils';
import './ProfileSettings.scss';
import PaymentSettings from '../Payment/PaymentSettings';
import DeletePaymentCardModal from '../CustomModal/CustomModal';
import getDeleteCardModalContent from '../Payment/DeleteCardModalContent';
import PaymentBillingInfo from '../Payment/PaymentBillingInfo';
import { getStringWithoutWhiteSpaces } from '../../Utils/TextUtils';
import { validatePhone } from '../../Utils/FormValidators';
import { emailValidator } from '../../Utils/Validators';
import ComapnyInfo from '../CompanyInfo/CompanyInfo';
import profileSettingMessages from './ProfileSettingMessages';
import UpdatePasswordButton from '../Auth/UpdatePasswordButton';

/**
 * @author Prashanth Banda
 * @email prashanth.banda@leoforce.com
 * @create date 2019-04-19 21:59:23
 * @modify date 2019-04-19 21:59:23
 */
class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwords: {
        oldPassword: '',
        newPassword: '',
      },
      currentEmailSignatures: [],
      selectedCountry: 'US',
      selectedLocation: undefined,
      locationInput: '',
      selectedPaymentProfileId: null,
    };
    this.navigationRefs = [];
    this.handleScroll = this.handleScroll.bind(this);
    this.submitBasicInfo = this.submitBasicInfo.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
    this.updateEmailSignature = this.updateEmailSignature.bind(this);
    this.submitSignature = this.submitSignature.bind(this);
    this.passwordValidation = this.passwordValidation.bind(this);
    this.onAddEmailAddress = this.onAddEmailAddress.bind(this);
    this.isSaveChangesButtonDisabled = this.isSaveChangesButtonDisabled.bind(this);
    this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
    this.isSaveAddressChangesButtonDisabled = this.isSaveAddressChangesButtonDisabled.bind(this);
  }

  componentDidMount() {
    const { userEmails, signatures } = this.props;
    this.setState({
      currentUserEmails: [...userEmails],
      currentEmailSignatures: signatures || [],
    });
  }

  componentDidUpdate(prevProps) {
    const { signatures, deletePaymentCardApiStatus, paymentProfile } = this.props;
    if (prevProps.signatures !== signatures) {
      this.setState({
        currentEmailSignatures: signatures,
      });
    }
    const nextDeletePaymentCardApiStatus = _.get(deletePaymentCardApiStatus, ['status']);
    const prevDeletePaymentCardApiStatus = _.get(prevProps, ['deletePaymentCardApiStatus', 'status']);
    if (prevDeletePaymentCardApiStatus !== 'COMPLETED' && nextDeletePaymentCardApiStatus === 'COMPLETED') {
      this.onDeletionSuccess();
    }
    if (paymentProfile.length > prevProps.paymentProfile.length) {
      this.selectCard(paymentProfile[0].ProfileId);
    }
  }

  onPasswordFieldChange(fieldName, value) {
    const { passwords } = this.state;
    this.setState({
      passwords: { ...passwords, [fieldName]: value },
    });
  }

  submitBasicInfo() {
    const { onUpdateBasicInfo, form } = this.props;
    const formValues = form.getFieldsValue();
    const { firstName, lastName, phoneNumber } = formValues;
    const updatedUser = { given_name: firstName, family_name: lastName, phone_number: phoneNumber };
    if (updatedUser.given_name && updatedUser.given_name.trim().length) {
      onUpdateBasicInfo(updatedUser);
    }
    this.submitSignature();
  }

  submitPassword(onSuccessfulPasswordUpdateCallback) {
    const { onUpdatePassword } = this.props;
    const { passwords } = this.state;
    onUpdatePassword(passwords.oldPassword, passwords.newPassword, onSuccessfulPasswordUpdateCallback);
  }

  submitSignature() {
    const { postEmailSignatures } = this.props;
    const { currentEmailSignatures } = this.state;
    const parsedEmailSignatures = _.cloneDeep(currentEmailSignatures);
    if (parsedEmailSignatures)
      for (let i = 0; i < parsedEmailSignatures.length; i += 1) {
        parsedEmailSignatures[i].Signature = parseHtmlStringFromEditor(parsedEmailSignatures[i].Signature, false);
      }
    postEmailSignatures(parsedEmailSignatures);
  }

  updateCompanyInfo() {
    const { updateUserDetails, form } = this.props;
    const company = form.getFieldValue('company');
    const zipcode = form.getFieldValue('selectZipCode');
    const country = form.getFieldValue('selectCountry');
    const locationArray = form.getFieldValue('selectLocation').split('@');
    const location = locationArray[locationArray.length - 1].split(',');
    const street = form.getFieldValue('street').trim();
    const state = (location[1] ?? '').trim();
    const city = (location[0] ?? '').trim();
    const userInfo = {
      OrgName: company,
      ZipCode: zipcode,
      Street: street,
      Country: country,
      State: state,
      City: city,
    };
    updateUserDetails(userInfo);
  }

  handleScroll(index) {
    const { navigationRefs } = this;
    if (window) {
      window.scrollTo({
        top: navigationRefs[index].offsetTop,
        behavior: 'smooth',
      });
    }
  }

  updateEmailSignature(emailId, htmlContent) {
    const { currentEmailSignatures } = this.state;
    const updatedEmailSignatures = _.cloneDeep(currentEmailSignatures);
    const emailIndex = _.findIndex(updatedEmailSignatures, signature => signature.EmailId === emailId);
    if (emailIndex === -1) {
      updatedEmailSignatures.push({ EmailId: emailId, Signature: htmlContent });
    } else {
      updatedEmailSignatures[emailIndex].Signature = htmlContent;
    }
    this.setState({
      currentEmailSignatures: updatedEmailSignatures,
    });
  }

  passwordValidation = (rule, value, callback) => {
    const regexCapital = /^(.*[A-Z].*)$/;
    const regexSmall = /^(.*[a-z].*)$/;
    const regexDigit = /^(.*[0-9].*)$/;
    const regexSpecial = /^(.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*)$/;
    const regexLength = /^(.*).{8,}$/;
    let callbackMessage = '';

    if (value.length > 20) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.lengthMustBeLabel} />;
    } else if (!regexLength.test(value)) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.lengthMustBeLabel} />;
    } else if (!regexCapital.test(value)) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.RequiresACapitalLetterLabel} />;
    } else if (!regexSmall.test(value)) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.RequiresASmallLetterLabel} />;
    } else if (!regexDigit.test(value)) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.RequiresADigitLabel} />;
    } else if (!regexSpecial.test(value)) {
      callbackMessage = <FormattedMessage {...profileSettingMessages.RequiresASpecialCharacterLabel} />;
    }

    if (callbackMessage !== '') {
      callback(callbackMessage);
    } else callback();
  };

  onAddEmailAddress() {
    const { form, addUserEmail } = this.props;
    const emailId = form.getFieldValue('emailAddress');
    addUserEmail(emailId, false);
    form.setFieldsValue({ emailAddress: '' });
  }

  isSaveChangesButtonDisabled() {
    const { currentEmailSignatures } = this.state;
    const { user, signatures, form } = this.props;
    const formValues = form.getFieldsValue();
    const { firstName, lastName, phoneNumber } = formValues;
    const updatedGivenName = firstName ? firstName.trim() : '';
    const updatedFamilyName = lastName ? lastName.trim() : '';
    const updatedPhoneNumber = phoneNumber ? phoneNumber.trim() : '';
    const userGivenName = user.given_name ? user.given_name.trim() : '';
    const userFamilyName = user.family_name ? user.family_name.trim() : '';
    const userPhoneNumber = user.phone_number ? user.phone_number.trim() : '';
    const currentEmailSignatureList = currentEmailSignatures ? currentEmailSignatures.map(sign => sign.Signature) : [];
    const signatureList = signatures ? signatures.map(sign => sign.Signature) : [];
    const isEmailSignaturesModified = !_.isEqual(currentEmailSignatureList, signatureList);
    return (
      !updatedGivenName ||
      (updatedGivenName === userGivenName &&
        updatedFamilyName === userFamilyName &&
        updatedPhoneNumber === userPhoneNumber &&
        !isEmailSignaturesModified)
    );
  }

  isSaveAddressChangesButtonDisabled() {
    const { form, userDetails } = this.props;
    const { OrgName, Street, City, State, ZipCode, Country } = userDetails;
    const _location = [City, State].join(', ');
    const company = form.getFieldValue('company');
    const zipcode = form.getFieldValue('selectZipCode');
    const country = form.getFieldValue('selectCountry');
    const locationArray = (form.getFieldValue('selectLocation') ?? '').split('@');
    const location = locationArray[locationArray.length - 1];
    const street = form.getFieldValue('street');

    return (
      !company ||
      !zipcode ||
      !country ||
      !location ||
      !street ||
      !street.trim().length ||
      street.trim().length > 60 ||
      (OrgName === company && ZipCode === zipcode && Country === country && Street === street && location === _location)
    );
  }

  currentAddress = () => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const address = getFieldValue('Address');
    const country = getFieldValue('Country');
    const addressLocation = getFieldValue('Location')?.trim()?.split(',');
    let city = null;
    let state = null;
    if (addressLocation && addressLocation.length > 1) {
      city = addressLocation[0].trim();
      state = addressLocation[1].trim();
    }
    const zipCode = getFieldValue('Zipcode');
    return {
      Street: address?.trim(),
      Country: country?.trim(),
      City: city,
      State: state,
      Zip: zipCode?.trim(),
    };
  };

  cardDetails = () => {
    const { form } = this.props;
    const { getFieldValue } = form;

    const name = getFieldValue('Name');
    const number = getFieldValue('Number');
    const date = getFieldValue('Date');
    const cvv = getFieldValue('Cvv');

    return {
      Name: name?.trim(),
      Number: getStringWithoutWhiteSpaces(number)?.trim(),
      ExpiryMonth: Number(date?.format('M')),
      ExpiryYear: date?.year(),
      CVV: cvv?.trim(),
    };
  };

  isCardDetailsInvalid = details => {
    const isCardDetailsEmpty = !Object.values(details).every(x => !!x);
    let cvvLength = 3;
    const cardVaildity = cardValidator.number(details.Number);
    if (_.get(cardVaildity, ['card', 'type'], null) === 'american-express') {
      cvvLength = 4;
    }
    return isCardDetailsEmpty || cvvLength !== details?.CVV?.length || !cardVaildity.isValid;
  };

  isSaveCardButtonDisabled() {
    const { paymentProfile, form } = this.props;
    const { getFieldValue } = form;
    const { selectedPaymentProfileId } = this.state;
    const selectedPaymentProfile = paymentProfile
      ? paymentProfile.find(profile => profile.ProfileId === selectedPaymentProfileId)
      : null;
    const selectedBillingAddress = _.get(selectedPaymentProfile, ['Address'], null);
    const currentAddress = this.currentAddress();
    const cardDetails = this.cardDetails();
    const address = getFieldValue('Address');
    const isCurrentAddressEmpty = !Object.values(currentAddress).every(x => !!x);
    const { FirstName, LastName, ...filteredBillingAddress } = selectedBillingAddress ?? {};
    const isCurrentAddressDifferent = _.isEqual(currentAddress, filteredBillingAddress);

    if (!paymentProfile.length) {
      const isCardDetailsInvalid = this.isCardDetailsInvalid(cardDetails);
      return isCardDetailsInvalid || isCurrentAddressEmpty || address.trim().length > 60;
    }
    return (
      !selectedPaymentProfileId || isCurrentAddressDifferent || isCurrentAddressEmpty || address.trim().length > 60
    );
  }

  updatePayment = async () => {
    const { updateBillingInfo, fetchPaymentProfile, savePaymentDetails } = this.props;
    const cardDetails = this.cardDetails();
    const address = this.currentAddress();
    const { selectedPaymentProfileId } = this.state;
    if (selectedPaymentProfileId) {
      await updateBillingInfo(selectedPaymentProfileId, address, true);
    } else {
      await savePaymentDetails(cardDetails, address, true);
    }
    await fetchPaymentProfile();
  };

  getEmailInputSendButtonEnableState = () => {
    const { form, userEmails } = this.props;
    const emailToAdd = form.getFieldValue('emailAddress');
    const emailsAdded = (userEmails || []).map(email => email.EmailId.toLowerCase());
    if (
      !emailToAdd ||
      !emailToAdd.trim().length ||
      emailsAdded.includes(emailToAdd.toLowerCase()) ||
      !emailValidator(emailToAdd)
    )
      return false;
    return true;
  };

  onDeleteUserEmail = async id => {
    const { deleteUserEmail, form } = this.props;
    await deleteUserEmail(id);
    const emailToAdd = form.getFieldValue('emailAddress');
    form.setFieldsValue({ emailAddress: emailToAdd });
  };

  onDeletionSuccess = () => {
    const { fetchPaymentProfile } = this.props;
    this.setState({
      selectedPaymentProfileId: null,
    });
    fetchPaymentProfile();
    this.hideDeleteCardModal();
  };

  selectCard = selectedPaymentProfileId => {
    this.setState({ selectedPaymentProfileId });
  };

  showDeleteCardModal = paymentProfileId => {
    this.setState({
      deleteCardModalVisibility: true,
      paymentProfileIdForDeletion: paymentProfileId,
    });
  };

  hideDeleteCardModal = () => {
    const { clearDeletePaymentCardApiStatus, deletePaymentCardApiStatus } = this.props;
    this.setState({
      deleteCardModalVisibility: false,
    });
    if (['FAILED', 'COMPLETED'].includes(_.get(deletePaymentCardApiStatus, ['status']))) {
      clearDeletePaymentCardApiStatus();
    }
  };

  toggleDeleteCardModal = () => {
    const { deleteCardModalVisibility } = this.state;
    this.setState({
      deleteCardModalVisibility: !deleteCardModalVisibility,
    });
  };

  render() {
    const { navigationRefs } = this;
    const countries = [{ Name: 'USA', Iso2Code: 'US', Iso3Code: 'USA' }];
    const { passwords, deleteCardModalVisibility, selectedPaymentProfileId, paymentProfileIdForDeletion } = this.state;
    const {
      form,
      isPaidService,
      userDetails,
      paymentProfile,
      savePaymentDetails,
      fetchPaymentProfile,
      resetSavePaymentDetailApiStatus,
      fetchLocations,
      fetchZipCodes,
      resetZipcodes,
      clearZipValidationError,
      searchZipCode,
      clearZipCodeError,
      jobUtilities,
      locationApiStatus,
      clearDeletePaymentCardApiStatus,
      deletePaymentCard,
      deletePaymentCardApiStatus,
      paymentProfilesById,
      savePaymentProfileApiStatus,
      userUtilities,
      fetchUserLocations,
      fetchUserZipCodes,
      isEmailWriteEnabled,
      user,
      location,
      intl,
    } = this.props;
    const passwordUpdated = passwords.oldPassword !== '' && passwords.newPassword !== '';

    const selectedPaymentProfile = paymentProfile
      ? paymentProfile.find(profile => profile.ProfileId === selectedPaymentProfileId)
      : null;
    const selectedBillingAddress = _.get(selectedPaymentProfile, ['Address'], null);

    return (
      <div className="settings-container">
        <div className="settings-navigator">
          <Affix offsetTop={80} className="navigator-group-wrapper">
            <div className="navigator-group">
              <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(0)}
                onKeyPress={() => this.handleScroll(0)}
              >
                <FormattedMessage {...profileSettingMessages.profileSettingBasicInfo} />
              </div>
              <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(1)}
                onKeyPress={() => this.handleScroll(1)}
              >
                <FormattedMessage {...profileSettingMessages.profileSettingSecurity} />
              </div>
              {isPaidService ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(2)}
                  onKeyPress={() => this.handleScroll(2)}
                >
                  <FormattedMessage {...profileSettingMessages.profileSettingCompanyInformation} />
                </div>
              ) : null}

              {isPaidService ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(3)}
                  onKeyPress={() => this.handleScroll(3)}
                >
                  <FormattedMessage {...profileSettingMessages.profileSettingPaymentInformation} />
                </div>
              ) : null}
            </div>
            <div className="vertical-line" />
          </Affix>
        </div>
        <div className="current-settings-wrapper">
          <div className="settings-section">
            <div className="basic-info-section">
              <div className="navigation-item-title-save">
                <div>
                  <div
                    className="navigation-item-title"
                    ref={input => {
                      navigationRefs[0] = input;
                    }}
                  >
                    <FormattedMessage {...profileSettingMessages.profileSettingBasicInfo} />
                  </div>
                  <div className="navigation-item-subtitle">
                    <FormattedMessage {...profileSettingMessages.basicProfileInformationAssociatedToThisAccountLabel} />
                  </div>
                </div>
                <Button
                  disabled={this.isSaveChangesButtonDisabled()}
                  type="primary"
                  shape="round"
                  onClick={this.submitBasicInfo}
                >
                  <FormattedMessage {...profileSettingMessages.profileSettingSaveChangesButton} />
                </Button>
              </div>
              <div className="navigation-item-form-wrapper">
                <Form.Item
                  label={<FormattedMessage {...profileSettingMessages.profileSettingLabelFirstName} />}
                  colon={false}
                >
                  {form.getFieldDecorator('firstName', { initialValue: user.given_name })(
                    <Input
                      className="profile-settings-input"
                      placeholder={intl.formatMessage({ ...profileSettingMessages.enterFirstnameRequiredLabel })}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage {...profileSettingMessages.profileSettingLabelLastName} />}
                  colon={false}
                >
                  {form.getFieldDecorator('lastName', { initialValue: user.family_name })(
                    <Input
                      className="profile-settings-input"
                      placeholder={intl.formatMessage({ ...profileSettingMessages.enterLastnameLabel })}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={<FormattedMessage {...profileSettingMessages.profileSettingLabelPhone} />}
                  colon={false}
                >
                  {form.getFieldDecorator('phoneNumber', {
                    rules: [
                      {
                        message: (
                          <FormattedMessage {...profileSettingMessages.profileSettingLabelInvalidPhoneMessage} />
                        ),
                        validator: validatePhone,
                      },
                    ],
                    initialValue: user.phone_number,
                  })(
                    <Input
                      className="profile-settings-input"
                      type="tel"
                      placeholder={intl.formatMessage({ ...profileSettingMessages.enterPhoneNumberLabel })}
                      name="phoneNumber"
                    />
                  )}
                </Form.Item>

                {!isPaidService && isEmailWriteEnabled ? (
                  <div>
                    <div className="form-item-email">
                      <Form.Item
                        label={<FormattedMessage {...profileSettingMessages.profileSettingLabelEmail} />}
                        colon={false}
                      >
                        {form.getFieldDecorator('emailAddress', {
                          initialValue: user?.email ?? '',
                        })(
                          <Input
                            className="profile-settings-email-input"
                            type="tel"
                            placeholder={intl.formatMessage({ ...profileSettingMessages.addEmailAddressLabel })}
                            name="emailAddress"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="settings-section">
            <div className="security-section">
              <div className="navigation-item-title-save">
                <div>
                  <div
                    className="navigation-item-title"
                    ref={input => {
                      navigationRefs[1] = input;
                    }}
                  >
                    <FormattedMessage {...profileSettingMessages.profileSettingSecurity} />
                  </div>
                  <div className="navigation-item-subtitle">
                    <FormattedMessage {...profileSettingMessages.profileSettingSecurityCredentialMessage} />
                  </div>
                </div>
                <UpdatePasswordButton
                  renderProp={onSuccessfulPasswordUpdateCallback => {
                    return (
                      <Button
                        type="primary"
                        disabled={!passwordUpdated}
                        shape="round"
                        onClick={() => this.submitPassword(onSuccessfulPasswordUpdateCallback)}
                      >
                        <FormattedMessage {...profileSettingMessages.profileSettingUpdatePasswordButton} />
                      </Button>
                    );
                  }}
                />
              </div>
              <div className="navigation-item-form-wrapper">
                <Form.Item
                  label={<FormattedMessage {...profileSettingMessages.profileSettingLabelCurrentPassword} />}
                  colon={false}
                >
                  {form.getFieldDecorator('Current Password', {
                    rules: [{ validator: this.passwordValidation }],
                  })(
                    <Input
                      className="profile-settings-input"
                      type="password"
                      placeholder={intl.formatMessage({ ...profileSettingMessages.enterCurrentPasswordLabel })}
                      onChange={e => this.onPasswordFieldChange('oldPassword', e.target.value)}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label={<FormattedMessage {...profileSettingMessages.profileSettingLabelNewPassword} />}
                  colon={false}
                >
                  <div style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
                    {form.getFieldDecorator('New Password', {
                      rules: [{ validator: this.passwordValidation }],
                    })(
                      <Input
                        className="profile-settings-input"
                        placeholder={intl.formatMessage({ ...profileSettingMessages.enterNewPasswordLabel })}
                        type="password"
                        onChange={e => this.onPasswordFieldChange('newPassword', e.target.value)}
                      />
                    )}

                    {passwordUpdated ? (
                      <div style={{ display: 'flex', gap: '6px', alignItems: 'center', color: '#1F2730' }}>
                        <Icon type="exclamation-circle" style={{ color: '#FAAD14' }} theme="filled" />
                        changing the password will log you out of this device
                      </div>
                    ) : null}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          {isPaidService ? (
            <div className="settings-section">
              <div clssName="company-info">
                <div className="navigation-item-title-save">
                  <div>
                    <div
                      className="navigation-item-title"
                      ref={input => {
                        navigationRefs[2] = input;
                      }}
                    >
                      <FormattedMessage {...profileSettingMessages.profileSettingCompanyInformation} />
                    </div>
                    <div className="navigation-item-subtitle">
                      <FormattedMessage {...profileSettingMessages.profileSettingCompanyInformationMessage} />
                    </div>
                  </div>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.updateCompanyInfo}
                    disabled={this.isSaveAddressChangesButtonDisabled()}
                  >
                    <FormattedMessage {...profileSettingMessages.profileSettingSaveChangesButton} />
                  </Button>
                </div>
                <ComapnyInfo
                  form={form}
                  fetchZipCodes={fetchUserZipCodes}
                  fetchLocations={fetchUserLocations}
                  utilities={userUtilities}
                  userDetails={userDetails}
                  hideSaveButton
                  hidePhoneNumber
                />
              </div>
            </div>
          ) : null}

          {isPaidService ? (
            <div className="settings-section">
              <div clssName="company-info">
                <div className="navigation-item-title-save">
                  <div>
                    <div
                      className="navigation-item-title"
                      ref={input => {
                        navigationRefs[3] = input;
                      }}
                    >
                      <FormattedMessage {...profileSettingMessages.profileSettingPaymentInformation} />
                    </div>
                    <div className="navigation-item-subtitle">
                      <FormattedMessage {...profileSettingMessages.profileSettingPaymentInformationMessage} />
                    </div>
                  </div>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.updatePayment}
                    disabled={this.isSaveCardButtonDisabled()}
                  >
                    <FormattedMessage {...profileSettingMessages.profileSettingSaveChangesButton} />
                  </Button>
                </div>
                <PaymentSettings
                  form={form}
                  paymentProfile={paymentProfile}
                  savePaymentDetails={savePaymentDetails}
                  fetchPaymentProfile={fetchPaymentProfile}
                  resetSavePaymentDetailApiStatus={resetSavePaymentDetailApiStatus}
                  selectCard={this.selectCard}
                  paymentProfileId={selectedPaymentProfileId}
                  showDeletePaymentCardModal={this.showDeleteCardModal}
                  savePaymentProfileApiStatus={savePaymentProfileApiStatus}
                />
                <div>
                  <div className="navigation-item-title">
                    <FormattedMessage {...profileSettingMessages.profileSettingBillingAddress} />
                  </div>
                  <div className="navigation-item-subtitle">
                    <FormattedMessage {...profileSettingMessages.profileSettingBillingAddressMessage} />
                  </div>
                </div>
                <PaymentBillingInfo
                  hideHeader
                  countries={countries}
                  form={form}
                  selectedAddress={selectedBillingAddress}
                  utilities={jobUtilities}
                  locationApiStatus={locationApiStatus}
                  fetchLocations={fetchLocations}
                  fetchZipCodes={fetchZipCodes}
                  resetZipcodes={resetZipcodes}
                  clearZipValidationError={clearZipValidationError}
                  searchZipCode={searchZipCode}
                  clearZipCodeError={clearZipCodeError}
                  paymentMode="CreditCard"
                  location={location}
                />
              </div>
              <DeletePaymentCardModal
                visible={deleteCardModalVisibility}
                toggleOrderModalVisibility={this.hideDeleteCardModal}
                modalContent={getDeleteCardModalContent({
                  deletePaymentCardApiStatus,
                  paymentProfileIdForDeletion,
                  paymentProfilesById,
                  deletePaymentCard,
                  clearDeletePaymentCardApiStatus,
                  showDeleteMessageModal: false,
                  showDeleteNotification: true,
                  toggleDeleteCardModal: this.toggleDeleteCardModal,
                })}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(Form.create()(ProfileSettings));

export { ProfileSettings as ProfileSettingsWithoutForm };
