const config = {
  appId: 'd31f44fd-11f3-43ba-a378-7b09df3bf900',
  aryaApiUrl: 'https://iarya.leoforce.com/iaryaapi/api',
  apiV1Url: 'https://app.goarya.com/api/v1',
  apiV2Url: 'https://app.goarya.com/api/v2',
  apiV3Url: 'https://app.goarya.com/api/v3',
  apiV4Url: 'https://app.goarya.com/api/v3.1',
  apiV5Url: 'https://app.goarya.com/api/v3.2',
  defaultSelectedPageSize: 10,
  defaultPageSizes: [10, 20],
  urls: {
    aryaPay: 'https://pay.goarya.com/api/v1',
    aryaConnectNotifications: 'https://connectapi.goarya.com/hubs/notification',
    aryaConnectCandidatePage: 'https://connect.goarya.com/chat',
    aryaConnect: 'https://connectapi.goarya.com/api/v1',
    aryaConnectV2Client: 'https://connect.goarya.com/v2',
    aryaCalendar: 'https://calendar.goarya.com',
    aryaConnectChat: 'https://connectapi.goarya.com/hubs/connect',
    aryaNotifySocketEndpoint: 'https://notify.goarya.com',
    aryaNotify: 'https://notify.goarya.com/api/v1',
    aryaConnectChatV2: 'https://connectapi.goarya.com/hubs/v2/connect',
    aryaConnectApiV2Client: 'https://connectapi.goarya.com/api/v2',
    pulseHostName: 'pulse.goarya.com',
    knowledgeBase: 'https://knowledgebase.goarya.com',
    getWhiteLabelResourceUrl: client => `https://arya3-whitelabel.s3.amazonaws.com/${client.toLowerCase()}-prod`,
    smartjob: 'https://jobsapi.goarya.com/api/v1',
    atlasApiUrl: 'https://atlas.goarya.com/api/v1',
    pulseMarketingUrl: 'https://goarya.com/arya-pulse/',
    getAppConfiguration: appType =>
      `https://arya3-whitelabel.s3.amazonaws.com/configuration-prod/settings-${appType}.json`,
    botSummaryViewUrl: 'https://pulse.goarya.com/jobs/{0}/candidates?status=connected',
    verifiedRedirectUri: 'https://aryapulse.page.link/get-started_login',
    appUrl: 'https://app.goarya.com',
    env: 'prod',
    getGtagUrl: key => `https://www.googletagmanager.com/gtag/js?id=${key}`,
    careerportalUrl:
      'https://portal.goarya.com/leoforce-internal/jobs/se/{{jobId}}/candidates/{{candidateId}}/_applicationReview?aTime={{appliedTime}}&r={{rId}}&acId={{aryaCandidateId}}',
    atsUserProfile: 'https://leoforce.us/Leo/Home/UserProfile',
    getSubmitCandidateUrl: (jobGuid, jobName) =>
      `https://portal.goarya.com/leoforce/jobs/${encodeURIComponent(jobName)}/${jobGuid}/_interestSupport`,
    aryaGpt: 'https://aryagpt.goarya.com',
  },
  mycareers: {
    clientId: '147795441DFFC675',
    url: 'https://portal.goarya.com',
  },
  whiteLabelClients: ['PeopleScout', 'Joveo', 'Churchstreeter'],
  ipstack: {
    url: 'https://api.ipstack.com',
    key: 'ee2475a24d765710e3af3367c3187678',
  },
  auth: {
    cognito: {
      clientId: '7ukb1d9th26qbinougkabni8l0',
      poolId: 'us-east-1_ykv3w7NGV',
      region: 'us-east-1',
      domain: 'aryaidentity.auth.us-east-1.amazoncognito.com',
      redirectUri: 'https://app.goarya.com',
      identityPoolId: 'us-east-1:63fdcba4-cdc5-45a5-a34f-db17fac31915',
      roleArn: 'arn:aws:iam::700190100279:role/Cognito_arya_ui_identity_pool_prod_Auth_Role',
      revokeEndpoint:'https://auth.goarya.com/oauth2/'
    },
  },
  googleAnalytics: {
    key: 'UA-203156803-1',
    container: 'GTM-TSHRF73',
  },
  advanceSearchActivity: {
    Api: 'https://iarya.leoforce.com/IAryaAPI/api/v3',
  },
  smartKarrot: {
    appId: 'cf1eabda-b946-4746-9ae4-6d703f4310b9',
    variant: 'option-c',
    productId: '2a1ef5ae-8fd5-4fa9-925b-19672a911e6f',
    isEnabled: true,
  },
  partnerstack: {
    key: 'pk_b34h4A8eMXD5Vnb1rhn3ljZITcVAs02W',
  },
  pivotRootGTMContainer: 'GTM-K7HKMSV',
  sipml: {
    publicIdentity: 'sip:1220@voip.goarya.com',
    privateIdentity: '1220',
    realm: 'voip.goarya.com',
    uri: '1220@voip.goarya.com',
    wsServers: 'wss://voip.goarya.com:443/ws',
    password: 'Le0$)rCe@(*&%$#2018@sPeC',
    displayName: '+19198000110',
    iceServers: [
      {
        urls: 'stun:voip-ice.goarya.com:3478',
      },
      {
        urls: 'turn:voip-ice.goarya.com:3478',
        username: 'aryavoip',
        credential: 'aryavoip@123',
      },
    ],
  },
  email: {
    oauth: {
      google: {
        baseUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        redirectUri: 'https://connectapi.goarya.com/api/v1/oauth',
        clientId: '904606246409-knlq6mss4ijcqvi8pg4lrvv5u0ilgkqd.apps.googleusercontent.com',
      },
      office365: {
        baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        redirectUri: 'https://connectapi.goarya.com/api/v1/oauth/',
        clientId: 'f835e106-8551-4c75-9863-9ecc56c4b24b',
      },
    },
  },
  portal: {
    oauth: {
      careerBuilderEdge: {
        baseUrl: 'https://auth.careerbuilder.com/connect/authorize?',
        redirectUri: 'https://app.goarya.com/api/v3/oauth',
        clientId: 'Cdcfe834d',
      },
    },
  },
  sso: {
    providers: [
      {
        path: '/lenovo',
        identifier: 'Lenovo',
      },
      {
        path: '/adprm',
        identifier: 'adprm',
      },
      {
        path: '/adpwn',
        identifier: 'adpwn',
      },
      {
        path: '/aramco',
        identifier: 'Aramco',
      },
      {
        path: '/aramcouk',
        identifier: 'aramcouk',
      },
      {
        path: '/firstadvantage',
        identifier: 'FirstAdvantage',
      },
      {
        path: '/fadv',
        identifier: 'FirstAdvantage',
      },
      {
        path: '/yoh',
        identifier: 'yoh',
      },
    ],
  },
  SMTP: {
    Providers: [
      {
        ProviderName: 'Manual',
        AuthScheme: 'Manual',
      },
      {
        ProviderName: 'google',
        AuthScheme: 'OAuth',
      },
    ],
  },
  ReactEmailEditor: { Bucket: 'arya-react-email-editor-prod' },
  Olark: { Id: '1271-114-10-1180' },
  Clarity: { Id: 'lutwc6nv1g' },
  trustedResumeResourceDomains: ['cv.cvlibrary.co.uk'],
  charactersPerMessage: 152,
  charactersPerNote: 65535,
  supportEmail: 'support@leoforce.com',
  getRequestCreditsEmailContent: ({ orgName, userName, amount, requestedCreditMessage, orgId }) => ({
    Subject: `Request to add ${requestedCreditMessage} credits from ${orgName}(${orgId}) - Admin level`,
    Body: `Requested ${requestedCreditMessage} credits from: ${orgName}(${orgId}), Requested by User Name: ${userName}, Requested Credits: ${amount}`,
  }),
  consentTemplateBody:
    'Hi {{Candidate.Name}}! Are you interested in exploring {{Job.Title}} opportunity at {{Organization.Name}}? Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities. Thanks, {{Recruiter.FirstName}}, Recruiter',
  consentTemplateName: 'Mandatory Consent Message',
  consentOption:
    'Please reply YES if interested, NO for not interested (or) STOP if you do not want to receive texts about {{Organization.Name}} Job opportunities.',
  privacyLink: 'https://goarya.com/data-privacy-policy/',
  termsOfUseLink: 'https://goarya.com/terms-of-use/',
  Workflow: {
    ScratchTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-prod/scratchtemplate.svg',
    DefaultTemplate: 'https://arya-workflow.s3.amazonaws.com/systemtemplate-prod/defaulttemplate.svg',
  },
  Product: {
    ProductVariants: [
      {
        Name: 'SMS',
        Id: '8E7565A7-24BB-11EF-B5B2-0AFB1557EA8F',
      },
    ],
  },
};

export default config;
