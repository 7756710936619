/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-03-17 13:46:18
 * @modify date 2019-03-17 13:46:18
 * @desc Signout component extending from Aws Amplify SignOut
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { SignOut } from 'aws-amplify-react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { handleLogout } from './HandleLogout';

class LogOutButton extends SignOut {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { signOut } = this.props;
    super.signOut();
    handleLogout(signOut);
  }

  render() {
    const { renderProp } = this.props;
    const client = localStorage.getItem('Client');
    const { hostname } = window.location;
    let linkUrl = '/';
    if (client && hostname.includes('goarya.com')) {
      linkUrl = `/${client}`;
    }
    return <Link to={`${linkUrl}`}>{renderProp(this.handleClick, <FormattedMessage {...messages.logout} />)}</Link>;
  }
}

export default LogOutButton;
